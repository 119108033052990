import axios from '@client/lib/axios'

export const updateVat = ({ vatInWebshop }) => {
  return axios({
    method: 'patch',
    url: '/api/2/accounts/update_vat',
    data: {
      vat_in_webshop: vatInWebshop
    }
  })
}

