const initialState = {
  token: null,
  count: 0,
  carts_products: []
}

export default (state=initialState, { type, payload }) => {
  let cart

  switch (type) {
    case 'FETCH_SESSION_SUCCESS':
    case 'CART_UPDATE_SUCCESS':
    case 'CART_FETCH_SUCCESS':
    case 'CART_BOOK_SUCCESS':
    case 'CARTS_PRODUCT_UPDATE_SUCCESS':
    case 'CARTS_PRODUCT_UPDATE_QUANTITY_SUCCESS':
    case 'CART_CLEAR_SUCCESS':
    case 'CART_REMOVE_COUPON_SUCCESS':
    case 'CART_APPLY_COUPON_SUCCESS':
      cart = payload.cart

      const count = cart.carts_products.filter(cartProduct => cartProduct.parent_id === null)
                                       .reduce((sum, p) => sum + p.qty, 0)

      return {
        ...state,
        ...cart,
        count
      }
    default:
      return state
  }
}
