window.addEventListener('turbolinks:load', () => {
  const toggleElements = (evt) => {
    const node = evt.target
    const selector = node.dataset.toggle || node.dataset.toggler
    const targets = document.querySelectorAll(selector)

    for (let target of targets) {
      target.classList.toggle('d-none')
    }
  }

  document.querySelectorAll('[data-toggle]').forEach(node => {
    node.addEventListener('change', toggleElements)
  })

  document.querySelectorAll('[data-toggler]').forEach(node => {
    node.addEventListener('click', toggleElements)
  })
})
