import Cookies from 'js-cookie'
import { updateVat } from '@client/api/account'

window.addEventListener('turbolinks:load', () => {

  const checkAndUpdateVatSettings = async (vatInclusive) => {
    const loggedIn = !_.isEmpty(window.customerData)

    Cookies.set('vat_in_webshop', vatInclusive)

    if (loggedIn) {
      try {
        await updateVat({ vatInWebshop: vatInclusive });
      } catch (error) {
        console.error('Error updating VAT:', error);
        return;
      }
    }

    window.location.reload();
  }
  
  const vatSelect = document.querySelector('#vat-switcher')
  
  if (vatSelect) {
    vatSelect.addEventListener('change', (e) => {
      checkAndUpdateVatSettings(e.target.value.includes('incl'))
    })
  }

  const vatDropdown = document.querySelector('#vat-dropdown')
  
  if (vatDropdown) {
    vatDropdown.addEventListener('click', (e) => {
      e.preventDefault()

      checkAndUpdateVatSettings(e.target.href.includes('incl'))
    })
  }
})
