window.addEventListener('turbolinks:load', () => {
  const elem = document.querySelector('#paginator')
  let loading = false

  if (!elem) { return }

  const isInViewport = (elem) => {
      const bounding = elem.getBoundingClientRect()
      const offset = 500

      return (
          bounding.top >= 0 &&
          bounding.left >= 0 &&
          bounding.bottom <= ((window.innerHeight || document.documentElement.clientHeight) + offset) &&
          bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
      )
  }

  const scrollListener = (e) => {
    if (loading) { return }

    if (isInViewport(elem)) {
      const button = $(elem).find('.load-more__button')
      const icon = $(elem).find('.load-more__icon')

      loading = true
      button.addClass('d-none')
      icon.removeClass('d-none')

      $.getScript(button.attr('href'), () => {
        loading = false
      })
    }
  }

  window.addEventListener('scroll', scrollListener)

  document.addEventListener('turbolinks:visit', () => {
    window.removeEventListener('scroll', scrollListener)
  })
})
