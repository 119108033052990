// Utils
import isMobile from '@client/utils/isMobile'
import { isQueryPresent } from '@client/utils/tweakwise'
import { i18n } from '../../../../app/javascript/packs/localized-i18n.js'

const INSTANCEKEY = "fe7dee6d";
const SCRIPT = "https://gateway.tweakwisenavigator.com/js/starter.js";

const INPUT_MOBILE = "tweakwise-input-mobile";
const INPUT_DESKTOP = "tweakwise-input-desktop";

const loadScript = function(src) {
  const script = document.createElement('script')

  script.type = "text/javascript";
  script.async = true;
  script.src = src;

  document.head.appendChild(script);
}

const screenwidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
let responsiveInput = INPUT_MOBILE;
if (screenwidth >= 768) responsiveInput = INPUT_DESKTOP;

const reloadTweakwise = () => {
  const tweakwiseInstance = window['twn-starter-instance'];

  if (tweakwiseInstance) {
    tweakwiseInstance.refresh();
    tweakwiseInstance.setIsSearchOpen(isQueryPresent());
  }
}

Object.defineProperty(window, "twn-starter-config", {
  value: {
    instancekey: INSTANCEKEY,
    input: responsiveInput,
    output: isMobile() ? "tweakwise-output-mobile" : "tweakwise-output",
    cid: TWEAKWISE_CONFIG['cid'],
    lang: i18n.locale,
    debug: true
  },
});

$(function() {
  loadScript(SCRIPT)
})

$(document).on('turbolinks:load', () => {
  reloadTweakwise()
})

window.addEventListener('popstate', () => {
  reloadTweakwise()
})
