import Turbolinks  from 'turbolinks'
import ReactRailsUJS from 'react_ujs'
import store from '@client/redux/store'

window.Turbolinks = Turbolinks
Turbolinks.start()
ReactRailsUJS.detectEvents()

document.addEventListener('turbolinks:visit', () => {
  Turbolinks.lastScrollPosition = window.scrollY
})

document.addEventListener('turbolinks:load', () => {
  if (window.restoreScrollPosition) {
    window.scrollTo(0, Turbolinks.lastScrollPosition)
    window.restoreScrollPosition = false
  }
})

document.addEventListener('turbolinks:before-render', () => {
  store.dispatch({
    type: 'ROUTE_CHANGE'
  })
})
