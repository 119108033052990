import unionBy from 'lodash/unionBy'

const initialState = []

export default (state=initialState, { type, payload }) => {
  switch (type) {
    case 'FETCH_SESSION_SUCCESS':
    case 'FETCH_DEPOTS_SUCCESS':
      return unionBy(state, payload.depots, 'id')
    case 'FETCH_DEPOT_SUCCESS':
      return payload.depot
    default:
      return state
  }
}
