const noneHeaderItemHovered = () => $('.header-categories__item:hover').length === 0;

$(document).on('turbolinks:load', () => {
  const $pageOverlay = $('#page-overlay');
  const headerHeight = $('#header-desktop').outerHeight();
  $pageOverlay.css('top', `${headerHeight}px`);

  const handlerIn = () => $pageOverlay.stop(true, true).fadeIn();
  const handlerOut = () => {
    if (noneHeaderItemHovered()) {
      $pageOverlay.stop(true, true).fadeOut();
    }
  };

  $('.header-categories__item').hover(handlerIn, handlerOut);
})
