import { fetch } from '@client/api/session'

const start = () => {
  return {
    type: 'FETCH_SESSION_START'
  }
}

const success = (payload) => {
  return {
    type: 'FETCH_SESSION_SUCCESS',
    payload: payload
  }
}

const error = ({ response }) => {
  return {
    type: 'FETCH_SESSION_ERROR',
    payload: { response }
  }
}

const fetchSession = () => {
  return (dispatch) => {
    dispatch(start())

    return fetch()
      .then(
        (response) => { dispatch(success(response.data)) },
        (errorData) => { dispatch(error({ response: errorData.response })) }
      )
  }
}

export default fetchSession
