import Isotope from 'isotope-layout'
import $ from 'jquery'

$(document).on('turbolinks:load', () => {
  const wrapper = document.querySelector('#reviews')

  if (wrapper) {
    window.grid = new Isotope(wrapper, {
      itemSelector: '.review-wrapper',
      masonry: {
        gutter: 0
      }
    })
  }
})
