const initialState = {
  open: false
}

export default (state=initialState, { type, payload }) => {
  switch (type) {
    case 'OPEN_PICKER':
      return {
        open: true
      }
    case 'CLOSE_PICKER':
      return initialState
    default:
      return state
  }
}
