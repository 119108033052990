import React from 'react'
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux'

import store from '@client/redux/store'
import ProductList from '@client/react/components/ProductList'

import { searchProducts } from '@client/api/products'

import isMobile from '@client/utils/isMobile'

const reduxComponent = (Component, props) => {
  return (
    <Provider store={store}>
      <Component {...props} />
    </Provider>
  )
}

const renderProductList = (products) => {
  const container = document.querySelector('.twn-starter__better-products')

  let component

  if (isMobile()) {
    component = ({ products }) => (
      <div className="row">
        <ProductList productClassName="col-12 col-md-6" products={products} type="list" wishlist={false} />
      </div>
    )
  } else {
    component = ({ products }) => (
      <div className="col">
        <div className="row gy-3">
          <ProductList productClassName="item-wrapper col-6 col-lg-4" products={products} />
        </div>
      </div>
    )
  }

  const root = createRoot(container)
  root.render(reduxComponent(component, { products }))
}

function onReadyStateChange() {
  if (this.readyState !== 4 /* Not completed */) return
  if (!this._url.includes("navigation-search")) return

  // Hide tweakwise output and put our own react component in its place
  document.querySelectorAll('.twn-starter__better-products').forEach(el => el.remove())
  const container = document.querySelector('.twn-starter__products')
  const div = document.createElement('div')
  container.classList.add('d-none')
  div.className = 'twn-starter__better-products'
  container.insertAdjacentElement('afterend', div)

  const items = JSON.parse(this.response).items
  const productIds = items.map(product => parseInt(product.itemno.split('-')[1]))
  searchProducts({ ids: productIds }).then((response) => response.data.products)
                                     .then(renderProductList)
}

const XHR = XMLHttpRequest
const open = XHR.prototype.open
const send = XHR.prototype.send

XHR.prototype.open = function (method, url, async, user, pass) {
  this._url = url

  open.call(this, method, url, async, user, pass)
}

XHR.prototype.send = function (data) {

  if (!this.noIntercept) {
    this.addEventListener("readystatechange", onReadyStateChange, false)
  }

  send.call(this, data)
}
