import { AdyenCheckout, Dropin } from '@adyen/adyen-web/auto';
import '@adyen/adyen-web/styles/adyen.css';

const initializeForm = async () => {
  const data = $('#dropin').data();

  const applePayConfiguration = {
    amount: {
      value: data.toBePaid,
      currency: "EUR"
    },
    countryCode: data.domainCountryCode,
  };

  const checkout = await AdyenCheckout({
    amount: {
      value: data.toBePaid,
      currency: 'EUR'
    },
    countryCode: data.domainCountryCode,
    locale: data.userLocale,
    environment: data.environment,
    clientKey: data.clientKey,
    paymentMethodsResponse: data.response,
    onError: (error, component) => {
        console.error(error.name, error.message, error.stack, component);
    },
    onSubmit: (state, dropin) => {
      if (!state.isValid) { return }

      $.post(data.submitUrl, state.data)
        .done(function(data) {
          if (data['action']) {
            dropin.handleAction(data['action']);
          } else {
            if (data['resultCode'] === 'Authorised') {
              dropin.setStatus('success');
            } else {
              if (data['error']) {
                dropin.setStatus('error', { message: data['message']});
              } else {
                dropin.setStatus('error');
              }

              $('#dropin-retry').removeClass('d-none');
            }
          }
        });
    }
  });

  const dropinConfiguration = {
    instantPaymentTypes: ['applepay'],

    paymentMethodsConfiguration: {
      applepay: applePayConfiguration
    }
  };

  const dropin = new Dropin(checkout, dropinConfiguration).mount('#dropin');

  $('#dropin-retry').on('click', function(e) {
    e.preventDefault();

    dropin.setStatus('ready');
    $('#dropin-retry').addClass('d-none');
  });
};

$(document).on('turbolinks:load', () => {
  if ($('#dropin').length > 0) {
    initializeForm();
  }
});
