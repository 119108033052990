import Turbolinks  from 'turbolinks'

// Makes element clickable.
//
// @example
//   <div data-href="/"></div>
//
$(document).on('turbolinks:load', () => {
  $('[data-href]').on('click', function(event) {
    if ($(event.target).is('a') || $(event.target.parentNode).is('a')) {
      return
    }

    Turbolinks.visit($(this).data('href'))
  })
})
