// Bootstrap carousel
import Carousel from '../../../vendor/javascripts/bootstrap/carousel';

import { tween, easing } from 'popmotion'

const carousel = (wrapper) => {
  const container = wrapper.querySelector('.carousel__item-container')
  const left = wrapper.querySelector('.carousel__left')
  const right = wrapper.querySelector('.carousel__right')

  let step = 0;

  const setStep = (newStep) => {
    step = newStep
  }

  const scrollTo = (from, to) => {
    if (from === to) return

    const { easeIn, mirrored } = easing
    const easeInOut = mirrored(easeIn)

    tween({ from, to, ease: easeInOut }).start((x) => {
      container.scrollLeft = x
    })
  }

  const handleStep = (minus = false) => {
    const elements = container.querySelectorAll('.carousel__item')
    const containerWidth = container.clientWidth
    const itemWidth = elements[0].getBoundingClientRect().width
    const itemsVisible = Math.round(containerWidth / itemWidth)
    const maxStep = elements.length - itemsVisible

    const add = minus ? itemsVisible * -1 : itemsVisible
    const newStep = Math.min(Math.max(step + add, 0), maxStep)

    scrollTo(step * itemWidth, newStep * itemWidth)
    setStep(newStep)
  }

  left.addEventListener('click', () => handleStep(true))
  right.addEventListener('click', () => handleStep(false))
}

window.addEventListener('turbolinks:load', () => {
  const wrappers = document.querySelectorAll('.carousel__wrap, #product-carousel-gallery')

  for (let wrapper of wrappers) {
    carousel(wrapper)
  }
})
