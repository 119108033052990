import { Onfido } from 'onfido-sdk-ui';

$(document).on('turbolinks:load', () => {
  const onfidoContainer = $('#onfido-mount');

  if (onfidoContainer.length > 0) {
    const token = onfidoContainer.data('onfido-token');
    const workflowRunId = onfidoContainer.data('workflow-run-id');
    const userLanguage = onfidoContainer.data('user-language');
    const redirectPath = onfidoContainer.data('redirect-path');

    Onfido.init({
      token: token,
      language: userLanguage,
      containerId: 'onfido-mount',
      onComplete: function (data) {
        window.location.href = redirectPath;
      },
      onError: function(error) {
        console.log(error);
      },
      workflowRunId: workflowRunId,
    });
  }
});
