// Utils
import isMobile from '@client/utils/isMobile'

window.addEventListener('turbolinks:load', () => {
  // Disable sticky header in tests, because it breaks a lot of e2e tests
  if (window.testMode) return

  const preroll = document.querySelector('.header-preroll')

  const offset = () => {
    return preroll.offsetHeight
  }

  const scrollListener = () => {
    const body = document.querySelector("body")
    const stickyheader = isMobile() ? document.querySelector('.sticky-header--mobile') : document.querySelector('.sticky-header')
    const scroll = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop

    if (scroll >= offset()) {
      stickyheader.classList.add('fixed')
      body.style.paddingTop = `${stickyheader.offsetHeight}px`
    } else {
      stickyheader.classList.remove('fixed')
      body.style.paddingTop = 0
    }
  }

  window.addEventListener('scroll', scrollListener)
})
