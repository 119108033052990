import setupComponents from './setup_components'
import store from 'client/javascripts/redux/store'

setupComponents(store)

import $ from 'jquery';
import { i18n } from './localized-i18n.js'

const images = require.context('client/images', true)
const imagePath = (name) => images(name, true)

import 'client/javascripts/app'
import 'client/stylesheets/app'

window.$ = $;
window.jQuery = $;
window.i18n = i18n;
