$(document).on('turbolinks:load', () => {
  $('.dropdown__item').on('mouseenter', function(e) {
    const wrap = $('.dropdown__wrap', this)
    const container = $(this).parents('.container-xxl')
    const containerOffset = container.outerWidth() + container.offset().left
    const isVisible = $(this).offset().left + wrap.outerWidth() <= containerOffset

    // reset wrapper offset for calculation
    wrap.css('left', 0)

    if (!isVisible) {
      const offset = containerOffset - wrap.offset().left - wrap.outerWidth()
      wrap.css('left', offset - 12)
    }
  })

  $('[data-dropdown]').on('click', function(e) {
    e.preventDefault()

    const node = this
    const selector = node.dataset.dropdown
    const target = node.closest(selector) || document.querySelector(selector)
    const parent = target.parentNode
    const icon = node.querySelector('.dropdown__icon')

    const handleClickOutside = (e) => {
      if (node === e.target || !target.contains(e.target)) {
        e.stopPropagation()
        hide()
      }
    }

    const show = () => {
      icon.classList.remove('fa-chevron-down')
      icon.classList.add('fa-chevron-up')
      parent.classList.add('dropdown--expanded')
      document.addEventListener('click', handleClickOutside)
    }

    const hide = () => {
      icon.classList.remove('fa-chevron-up')
      icon.classList.add('fa-chevron-down')
      parent.classList.remove('dropdown--expanded')
      document.removeEventListener('click', handleClickOutside)
    }

    if ($(target).is(':hidden')) {
      e.stopPropagation()
      show()
    } else {
      hide()
    }
  })
})
