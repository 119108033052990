// Libraries
import sortBy from 'lodash/sortBy'

const initialState = []

const sortState = (wishlists) => {
  return sortBy((wishlists), (wishlist) => wishlist.created_at)
}

export default (state=initialState, { type, payload }) => {
  switch (type) {
    case 'FETCH_WISHLISTS_SUCCESS':
    case 'FETCH_SESSION_SUCCESS':
      return sortState([
        ...payload.wishlists
      ])
    case 'FETCH_WISHLIST_SUCCESS':
    case 'ADD_TO_WISHLIST_SUCCESS':
    case 'REMOVE_FROM_WISHLIST_SUCCESS':
      const wishlists = state.filter((wishlist) => wishlist.id !== payload.wishlist.id)

      return sortState([
        ...wishlists,
        payload.wishlist
      ])
    case 'CREATE_WISHLIST_SUCCESS':
      return sortState([
        ...state,
        payload.wishlist
      ])
    default:
      return state
  }
}
