const initialState = {}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'FETCH_SESSION_SUCCESS':
      return payload.domain
    default:
      return state
  }
}
