import Cookies from 'js-cookie'
import getUrlParam from '@client/utils/getUrlParam'
import { track } from '@client/utils/tracking'

const cookieOptions = { expires: 90 }
const source = getUrlParam('source') || getUrlParam('utm_source') || getUrlParam('ref')
const campaign = getUrlParam('campaign') || getUrlParam('utm_campaign')
const variant = getUrlParam('variant') || getUrlParam('utm_content')
const keyword = getUrlParam('keyword') || getUrlParam('utm_term')

// Disable tracking for developers
if (getUrlParam('development')) {
  Cookies.set('development', true, cookieOptions)
}

if (source) {
  Cookies.set('tracking_source', source, cookieOptions)
}

if (campaign) {
  Cookies.set('tracking_campaign', campaign, cookieOptions)
}

if (variant) {
  Cookies.set('variant', variant, cookieOptions)
}

if (keyword) {
  Cookies.set('keyword', keyword, cookieOptions)
}

// Global methods
window.tracking = {
  track
}

window.addEventListener('turbolinks:load', () => {
  track('page.visit', { url: window.location.href.split('#')[0], title: document.title })
})

track('customer', window.customerData)
