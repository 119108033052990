const initialState = {}

export default (state=initialState, { type, payload }) => {
  switch (type) {
    case 'FETCH_PRODUCT_PRICES_SUCCESS':
      return {
        ...state,
        ...payload.prices
      }
    default:
      return state
  }
}
