const initialState = {}

export default (state=initialState, { type, payload }) => {
  switch (type) {
    case 'FETCH_SESSION_SUCCESS':
    case 'SIGN_IN_SUCCESS':
      return payload.user
    default:
      return state
  }
}
