$(document).on('turbolinks:load', () => {
  $('.mobile-menu__icon').on('click', function(e) {
    const self = $(this)

    self.toggleClass('is-active')

    if (self.hasClass('is-active')) {
      $('.mobile-sidemenu').removeClass('hidden-menu')
      $('.mobile-sidemenu__wrap').removeClass('d-none')
    } else {
      $('.mobile-sidemenu').addClass('hidden-menu')
    }
  })
})
