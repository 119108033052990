import unionBy from 'lodash/unionBy'

const initialState = []

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'FETCH_SESSION_SUCCESS':
    case 'FETCH_TIMESLOTS_SUCCESS':
      return unionBy(state, payload.timeslots, 'id')
    default:
      return state
  }
}
